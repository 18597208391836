@tailwind base;
@tailwind components;

@layer base {
	html {
		@apply box-border;
	}
	*,
	*:before,
	*:after {
		@apply box-border;
	}

	@font-face {
		font-family: custom-poppins;
		src: url(./assets/fonts/Poppins-Light.ttf);
		font-weight: 300;
	}
	@font-face {
		font-family: custom-poppins;
		src: url(./assets/fonts/Poppins-Regular.ttf);
		font-weight: 400;
	}
	@font-face {
		font-family: custom-poppins;
		src: url(./assets/fonts/Poppins-Medium.ttf);
		font-weight: 500;
	}
	@font-face {
		font-family: custom-poppins;
		src: url(./assets/fonts/Poppins-SemiBold.ttf);
		font-weight: bold;
	}

	@font-face {
		font-family: 'text-security';
		src: url(./assets/fonts/text-security-disc-compat.eot);
		src: url(./assets/fonts/text-security-disc-compat.eot?#iefix) format('embedded-opentype'),
			url(./assets/fonts/text-security-disc.woff2) format('woff2'),
			url(./assets/fonts/text-security-disc-compat.ttf) format('truetype');
		}

		/*
		Browser detection hack to enable the optimized font in recent versions of Chromium and FF,
		which implement their own font rendering and do support cmap format 13.
		content-visibility is currently only supported in Chrome & Edge version 85+ & Chrome for Android
		-moz-appearance only lets Firefox through
		-webkit-hyphens explicitly excludes Safari in case they later add support for content-visibility
		*/
		@supports ((content-visibility: visible) or (-moz-appearance:meter)) and (not (-webkit-hyphens: none)) {
		@font-face {
			font-family: 'text-security';
			src: url(./assets/fonts/text-security-disc.woff2) format('woff2'),
			url(./assets/fonts/text-security-disc-compat.ttf) format('truetype');
		}
	}

	select {
		@apply font-system;
	}

	input {
		@apply font-system;
	}

	textarea {
		@apply font-system;
	}
}

@tailwind utilities;


/* Calendar control stylings*/
.Range
	.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
	@apply bg-secondary text-primary;
}
.Range
	.DayPicker-Day--start:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
	@apply bg-secondary text-primary rounded-tl-full rounded-bl-full;
}
.Range
	.DayPicker-Day--start:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
	@apply bg-secondary text-primary rounded-tl-full rounded-bl-full;
}
.Range
	.DayPicker-Day--end:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
	@apply bg-secondary text-primary rounded-br-full rounded-tr-full;
}
.Range
	.DayPicker-Day--end:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
	@apply bg-secondary text-primary rounded-br-full rounded-tr-full;
}
.Range .DayPicker-Day--selected.DayPicker-Day--outside {
	@apply opacity-0;
}
.Range .DayPicker-Day {
	@apply rounded-none outline-none p-1;
}
.Range .DayPicker-Day--today {
	@apply text-base-700 text-sm font-normal;
}
.DayPicker-Day--outside {
	@apply w-10;
}
.Range .DayPicker-Caption {
	@apply hidden;
}
.Range .DayPicker-Week {
	@apply flex border-b-8 border-base-100;
}
.Range .DayPicker-WeekdaysRow {
	@apply flex;
}
.Range .DayPicker-Weekday {
	@apply w-10;
}
.Range .DayPicker-Month {
	@apply m-0 ml-2 mr-2;
}


/* Multi select styling */
select[multiple]:focus option:checked {
	@apply bg-gradient-to-t from-selected via-selected to-selected;
}

select[multiple] option:checked {
	@apply bg-selected text-base-100;
}

.password-field {
  font-family: text-security;
  /* Use -webkit-text-security if the browser supports it */
  -webkit-text-security: disc;
}

.comecer-table td {
	@apply p-2;
}

.comecer-table td:first-child {
	@apply rounded-l;
	@apply overflow-hidden;
}
